import React, {useState, useEffect} from "react";
import Logo from "../../static/images/logo.png";

function Footer(props) {
    const [bgColor, setBgColor] = useState(props.backgroundColor);

    useEffect(() => {
        setBgColor(props.backgroundColor);
    }, [props.backgroundColor])

    return (
        <footer className="d-flex justify-content-center align-items-center p-2" style={{backgroundColor: bgColor, color: "white", minHeight: "150px"}}>
            <div>
                <img src={Logo} className="img-fluid" width="80px" alt="Kat Devs Logo" />
            </div>
            <div className="flex-wrap">
                <div>
                    <span>Kat Devs | 36.434.636/0001-99</span>
                </div>
                <div>
                    <span>Rua Tamoyo, 155 - Teresópolis, RJ - 25954240</span>
                </div>
            </div>
        </footer>
    )
}

export default Footer;